.ql-toolbar {
  background-color: var(--basic-color-100);
  border-radius: 5px 5px 0px 0px;
}

.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 7.5px 14px;
  border-bottom: none;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: var(--basic-color-700);
}

.ql-container {
  min-height: 0;
  border-radius: 0px 0px 5px 5px;
}

.ql-editor {
  max-height: 170px;
  min-height: 170px;
  padding: 10px 14px;
  font-size: var(--basic-fz);
  line-height: 1.429;
  word-break: break-word;
  color: var(--basic-color);
  overflow-y: auto;
}

/* Выравнивание текста */
.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

/* Списки */
.ql-editor ul {
  list-style-type: disc;
  padding-left: 20px;
}

.ql-editor ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.ql-direction-rtl {
  direction: rtl;
  text-align: right;
}

.ql-align-rtl {
  direction: rtl;
}

/* Форматы текста */
.ql-blockquote {
  border-left: 4px solid var(--basic-color-700);
  padding-left: 10px;
  margin: 5px 0;
  color: var(--basic-color-700);
  font-style: italic;
}

.ql-code-block {
  background: var(--basic-color-200);
  color: var(--basic-color-900);
  font-family: monospace;
  padding: 5px 10px;
  border-radius: 3px;
}

.ql-pre {
  white-space: pre-wrap;
}

/* Стили для встраиваемых медиа */
.ql-editor iframe {
  max-width: 420px;
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Медиа-запрос для мобильных */
@media (max-width: 550px) {
  .ql-editor {
    max-height: 130px !important;
    min-height: 60px !important;
    padding: 27px 14px 15px 14px !important;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: var(--basic-color-700);
}

.ql-snow .ql-picker.ql-size {
  width: 70px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  padding: 0;
}

.ql-snow.ql-toolbar button {
  width: 22px;
  padding: 0;
}

button:hover svg path {
  fill: var(--secondary-color);
}

button.ql-active svg path {
  fill: var(--secondary-color);
}

/* Размеры шрифтов */
.ql-size-12px {
  font-size: 12px;
}

.ql-size-16px {
  font-size: 16px;
}

.ql-size-18px {
  font-size: 18px;
}

.ql-size-20px {
  font-size: 20px;
}

.ql-size-22px {
  font-size: 22px;
}

.sketch-picker {
  position: absolute;
  z-index: 2;
  top: 100%;
}
